import React from 'react';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import PropTypes from 'prop-types';
import { useAlertMessage } from '@fingo/lib/hooks';
import ClientConfigForm from '../forms';

const ClientConfigDialog = ({ open, setOpen, debtorId, refetchQueries }) => {
  const [alertMessage, setAlertMessage] = useAlertMessage();

  return (
    <FingoDialog
      open={open}
      title="Configuración de alertas"
      handleClose={() => {
        setOpen(false);
        setAlertMessage({ show: false });
      }}
      dialogAlert={alertMessage}
      setDialogAlert={setAlertMessage}
    >
      <ClientConfigForm
        debtorId={debtorId}
        handleClose={() => {
          setOpen(false);
        }}
        refetchQueries={refetchQueries}
        setAlertMessage={setAlertMessage}
      />
    </FingoDialog>
  );
};

ClientConfigDialog.propTypes = {
  debtorId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  refetchQueries: PropTypes.func,
};

ClientConfigDialog.defaultProps = {
  debtorId: null,
  refetchQueries: () => {},
};

export default ClientConfigDialog;
