import React, { useCallback } from 'react';
import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { prettifyRut, removeSeparators } from 'react-rut-formatter';

const RutField = () => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    validateField,
    touched,
    errors,
  } = useFormikContext();

  const handleRutChange = useCallback(
    (e) => {
      const newRut = e.target.value;
      const cleanedRut = removeSeparators(newRut);
      const formattedRut = prettifyRut(cleanedRut);
      const displayRut = formattedRut !== '0-0' ? formattedRut : '';
      setFieldValue('rut', { formatted: displayRut, raw: cleanedRut }, false);
      setFieldTouched('rut', false);
    },
    [setFieldValue, setFieldTouched, validateField],
  );

  const handleRutBlur = useCallback(() => {
    validateField('rut');
    setFieldTouched('rut', true);
  }, [validateField, setFieldTouched]);

  return (
    <TextField
      name="rut"
      label="RUT usuario"
      variant="filled"
      fullWidth
      value={values.rut.formatted}
      onChange={handleRutChange}
      onBlur={handleRutBlur}
      error={touched.rut?.raw && !!errors.rut?.raw}
      helperText={
        touched.rut?.raw && errors.rut?.raw
          ? errors.rut.raw
          : ' '
      }
      InputProps={{
        sx: {
          border: '1px solid #ccc',
          backgroundColor: '#f9f9f9',
          '&:hover': { backgroundColor: '#F9F9F9' },
        },
      }}
      FormHelperTextProps={{ sx: { minHeight: 24 } }}
    />
  );
};

export default RutField;
