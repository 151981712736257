import React from 'react';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import PropTypes from 'prop-types';
import { UploadOperationalDocumentsForm } from '@fingo/lib/components/forms';

const UploadOperationalDocuments = ({ open, setOpen }) => (
  <FingoDialog
    title="Carga de documentos operacionales"
    subtitle={null}
    open={open}
    handleClose={() => setOpen(false)}
    maxWidth="sm"
  >
    <UploadOperationalDocumentsForm />
  </FingoDialog>
);

UploadOperationalDocuments.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default UploadOperationalDocuments;
