import React from 'react';
import { FormControlLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import FingoCheckBox from './FingoCheckBox';

const FormControlCheckBox = ({ checked, handleChange, name, message, ...props }) => (
  <FormControlLabel
    sx={{ marginTop: 1 }}
    control={(
      <FingoCheckBox
        checked={checked}
        onChange={handleChange}
        name={name}
      />
    )}
    label={(
      <>
        <Typography display="inline">{message}</Typography>
      </>
    )}
    {...props}
  />
);

FormControlCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default FormControlCheckBox;
