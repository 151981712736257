import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { FingoDateRangePicker } from '@fingo/lib/components/datePickers';
import { LoadingButton } from '@mui/lab';
import { Formik, Form, Field } from 'formik';
import DateInput from '@fingo/lib/components/datePickers/DateInput';
import FingoDialog from '../dialogs/FingoDialog';
import usePortfolioDownloadForm from '../../hooks/usePortfolioForm'; // Asegúrate de importar el hook correctamente

const PortfolioDownload = ({ open, onClose, product, onlyHistoric }) => {
  const {
    formik,
    portfolioFormats,
    portfolioTypes,
    loading,
    loadingPortfolio,
    handleRangeChange,
  } = usePortfolioDownloadForm(product);

  return (
    <FingoDialog
      open={open}
      handleClose={onClose}
      title="Descargar resumen de cartera"
    >
      {loading && <LinearProgress />}
      {!loading && (
        <Formik
          initialValues={formik.initialValues}
          validationSchema={formik.validationSchema}
          onSubmit={formik.handleSubmit}
        >
          <Form id="portfolioDownload" noValidate>
            <Stack width="100%" spacing={2} alignItems="center">
              {!onlyHistoric && (
                <FormControl
                  required
                  fullWidth
                  error={
                    formik.touched.portfolioType
                    && !!formik.errors.portfolioType
                  }
                >
                  <FormLabel>Tipo de documento</FormLabel>
                  <Field
                    as={Select}
                    name="portfolioType"
                    onChange={formik.handleChange}
                    value={formik.values.portfolioType}
                  >
                    {portfolioTypes.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Field>
                  {formik.touched.portfolioType
                    && formik.errors.portfolioType && (
                      <div style={{ color: 'red' }}>
                        {formik.errors.portfolioType}
                      </div>
                  )}
                </FormControl>
              )}
              <FormControl
                required
                fullWidth
                error={
                  formik.touched.documentFormat
                  && !!formik.errors.documentFormat
                }
              >
                <FormLabel>Formato</FormLabel>
                <Field
                  as={Select}
                  name="documentFormat"
                  onChange={formik.handleChange}
                  value={formik.values.documentFormat}
                >
                  {portfolioFormats.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Field>
                {formik.touched.documentFormat
                  && formik.errors.documentFormat && (
                    <div style={{ color: 'red' }}>
                      {formik.errors.documentFormat}
                    </div>
                )}
              </FormControl>
              <FormControl
                required
                fullWidth
                error={formik.touched.startDate && !!formik.errors.startDate}
              >
                <DateInput
                  startDate={formik.values.startDate}
                  endDate={formik.values.endDate}
                  PickerComponent={FingoDateRangePicker}
                  pickerProps={{
                    onChange: handleRangeChange,
                    ranges: [
                      {
                        startDate: formik.values.startDate?.toDate(),
                        endDate: formik.values.endDate?.toDate(),
                        key: 'range',
                        color: 'primary.main',
                      },
                    ],
                    direction: 'horizontal',
                  }}
                />
                {formik.touched.startDate && formik.errors.startDate && (
                  <div style={{ color: 'red' }}>{formik.errors.startDate}</div>
                )}
                {formik.touched.endDate && formik.errors.endDate && (
                  <div style={{ color: 'red' }}>{formik.errors.endDate}</div>
                )}
              </FormControl>
              <LoadingButton
                variant="contained"
                size="small"
                color="primary"
                type="submit"
                loading={loadingPortfolio}
                id="download-portfolio"
              >
                Descargar resumen
              </LoadingButton>
            </Stack>
          </Form>
        </Formik>
      )}
    </FingoDialog>
  );
};

PortfolioDownload.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  product: PropTypes.string.isRequired,
  onlyHistoric: PropTypes.bool,
};

PortfolioDownload.defaultProps = {
  onlyHistoric: false,
};

export default PortfolioDownload;
