import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  Typography,
  Grid,
  RadioGroup,
  Radio,
  Hidden,
  FormControlLabel,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';

const FirstStepDialog = (props) => {
  const { grade,
    handleNext,
    handleClose,
    setGrade, title } = props;

  function numberColor(value) {
    if (value <= 5) return 'gray';
    if (value <= 7) return '#EA1C7F';
    return 'white';
  }

  function getGradeSquare(value) {
    if (value <= 5) return '#D7D5D6';
    if (value <= 7) return '#FFCEE9';
    return '#EA1C7F';
  }

  return (
    <>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <Grid container direction="column" justifyContent="center" alignItems="center" fontWeight="fontWeightMedium">
          <Grid item xs={12}>
            <DialogTitle id="alert-dialog-title">
              {title}
            </DialogTitle>
          </Grid>
          <DialogContent>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={grade}
                    onChange={(e) => setGrade(parseInt(e.target.value, 10))}
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                  >
                    <Grid container direction="row" columns={{ xs: 5, md: 10 }}>
                      {[...Array(10).keys()].map((value) => (
                        <Grid item key={`array ${value}`} xs={1}>
                          <Grid mx="0.1rem" my="0.2rem">
                            <FormControlLabel
                              key={value}
                              value={value + 1}
                              sx={{ backgroundColor: getGradeSquare(value), width: '100%', height: '100%', m: 'auto', borderRadius: '2px' }}
                              control={(
                                <Radio
                                  sx={{ backgroundColor: 'white', borderRadius: '50%', width: '1.2rem', height: '1.2rem', m: '1rem' }}
                                  color="secondary"
                                />
)}
                              label={
                                <Typography sx={{ fontSize: '0.8rem', mt: '0.3rem' }} color={numberColor(value)}>{value + 1}</Typography>
                            }
                              labelPlacement="top"
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <Hidden mdDown>
                      <Stack mt="10px" direction="row" justifyContent="space-between" width="100%">
                        <Stack textAlign="left">
                          <Typography>Poco</Typography>
                          <Typography>probable</Typography>
                        </Stack>
                        <Stack textAlign="right">
                          <Typography>Muy</Typography>
                          <Typography>probable</Typography>
                        </Stack>
                      </Stack>
                    </Hidden>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleNext}
              color="primary"
              disabled={grade === 0}
              variant="outlined"
              style={{ marginBottom: '5px' }}
            >
              Siguiente
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>

    </>
  );
};

FirstStepDialog.propTypes = {
  grade: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setGrade: PropTypes.func.isRequired,
};

export default FirstStepDialog;
