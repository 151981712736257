import React from 'react';
import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';

const EmailField = () => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext();

  return (
    <TextField
      name="email"
      label="Correo Electrónico"
      variant="filled"
      fullWidth
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      error={touched.email && !!errors.email}
      helperText={touched.email && errors.email ? errors.email : ' '}
      InputProps={{
        sx: {
          border: '1px solid #ccc',
          backgroundColor: '#f9f9f9',
          '&:hover': { backgroundColor: '#F9F9F9' },
        },
      }}
      FormHelperTextProps={{ sx: { minHeight: 24 } }}
    />
  );
};

export default EmailField;
