import React from 'react';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import PropTypes from 'prop-types';
import { UploadCreditLineDocumentsForm } from '@fingo/lib/components/forms';

const UploadCreditLineDocuments = ({ open, setOpen }) => (
  <FingoDialog
    title="Carga de documentos para evaluación"
    subtitle={null}
    open={open}
    handleClose={() => setOpen(false)}
    maxWidth="sm"
  >
    <UploadCreditLineDocumentsForm />
  </FingoDialog>
);

UploadCreditLineDocuments.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default UploadCreditLineDocuments;
