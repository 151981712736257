import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from '@mui/material';

const AlertTwoButtons = ({ open, onClose, onAccept, message, title, closeMsg, acceptMsg }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      {message}
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        color="primary"
      >
        {closeMsg}
      </Button>
      <Button
        onClick={onAccept}
        color="primary"
      >
        {acceptMsg}
      </Button>
    </DialogActions>
  </Dialog>
);

AlertTwoButtons.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  message: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  closeMsg: PropTypes.string,
  acceptMsg: PropTypes.string,
};

AlertTwoButtons.defaultProps = {
  closeMsg: 'Cancelar',
  acceptMsg: 'Aceptar',
};

export default AlertTwoButtons;
