import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useBooleanState, useSelectedCompany } from '@fingo/lib/hooks';
import { Button, Grid } from '@mui/material';
import { CESSIONS_SUMMARY } from '@fingo/lib/graphql';
import { SummaryCard } from '@fingo/lib/components/cards';
import { formatMoney } from '@fingo/lib/helpers';
import { CessionInvoicesFilter } from '@fingo/lib/components/filters';
import PorftolioDownload from '../PorfolioDownload';
import Surplus from '../Surplus';

const ChileCessionsSummary = ({ setCessionInvoices }) => {
  const [portfolioOpen, togglePortfolio] = useBooleanState(false);
  const selectedCompany = useSelectedCompany();
  const { data: cessionsSummary, loading } = useQuery(
    CESSIONS_SUMMARY,
    { variables: { companyId: selectedCompany?.id }, skip: !selectedCompany },
  );
  const showSymbol = true;
  return (
    <>
      <Grid
        container
        padding={2}
        alignItems="center"
        justifyContent="space-around"
        sx={{
          bgcolor: 'background.light',
          borderRadius: 2,
          flexWrap: 'wrap',
        }}
        rowSpacing={{ lg: 0, xs: 1, md: 1 }}
      >
        <Grid item xs={4.5} lg={2}>
          <SummaryCard
            title={`${cessionsSummary?.cessionsSummary.invoiceCount} | ${formatMoney(cessionsSummary?.cessionsSummary.amount, showSymbol)}`}
            subtitle="Facturas cedidas"
            loading={loading}
          />
        </Grid>
        <Grid item xs={4.5} lg={2}>
          <SummaryCard
            title={`${cessionsSummary?.cessionsSummary.debtCount} | ${formatMoney(cessionsSummary?.cessionsSummary.debtAmount, showSymbol)}`}
            subtitle="Facturas en mora"
            loading={loading}
          />
        </Grid>
        <Grid item xs={4.5} lg={2}>
          <SummaryCard
            title={cessionsSummary?.cessionsSummary.availableSurplus}
            subtitle="Excedentes totales"
            isMoney
            loading={loading}
          />
        </Grid>
        <Grid item xs={4.5} lg={1.7}>
          <Surplus />
        </Grid>
        <Grid item xs={4.5} lg={1.7}>
          <CessionInvoicesFilter setCessionInvoicesCallback={setCessionInvoices} />
        </Grid>
        <Grid item xs={4.5} lg={1.7}>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            onClick={togglePortfolio}
            id="toggle-download-portfolio"
          >
            Descargar resumen
          </Button>
        </Grid>
      </Grid>
      <PorftolioDownload open={portfolioOpen} onClose={togglePortfolio} product="FACTORING" />
    </>
  );
};

ChileCessionsSummary.propTypes = {
  setCessionInvoices: PropTypes.func,
};

ChileCessionsSummary.defaultProps = {
  setCessionInvoices: () => {},
};
export default ChileCessionsSummary;
