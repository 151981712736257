import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  FormGroup,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';

const SecondStepDialog = (props) => {
  const { comment,
    reasons,
    getFormItem,
    setComment,
    buttonDisabled,
    secondStepTitle,
    handleClose,
    handleFinish } = props;

  return (
    <>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >

        <Grid container direction="column" justifyContent="center">
          <DialogTitle fontSize="medium" id="alert-dialog-title">
            {secondStepTitle()}
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" justifyContent="center">
              <Grid item xs={12}>
                <FormControl required component="fieldset" fullWidth>
                  <FormGroup>
                    {reasons.map((reason, i) => getFormItem(reason, i))}
                  </FormGroup>
                  {reasons.length > 0 && (
                    <Typography my={1}>Comentarios opcionales</Typography>
                  )}
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <Grid item>
            <DialogActions>
              <Button
                onClick={handleFinish}
                color="primary"
                disabled={reasons.length > 0 ? buttonDisabled() : false}
              >
                Finalizar
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>

    </>
  );
};

SecondStepDialog.propTypes = {
  comment: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  reasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  getFormItem: PropTypes.func.isRequired,
  setComment: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.func.isRequired,
  secondStepTitle: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleFinish: PropTypes.func.isRequired,
};

export default SecondStepDialog;
